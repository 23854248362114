// main.ts
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";



platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));

// if (environment.hmr) {
//     if (module["hot"]) {
//         hmrBootstrap(module, bootstrap);
//     } else {
//         console.error("HMR is not enabled for webpack-dev-server!");
//         console.log("Are you using the --hmr flag for ng serve?");
//     }
// } else {
//     bootstrap().catch((err) => console.log(err));
// }
