import { FuseNavigationItem } from '@fuse/components/navigation';

export default function CommunityManagerNav() {

    const nav: FuseNavigationItem[] = [
        {
            id: 'crm',
            title: 'CRM',
            subtitle: 'Interactua con los clientes',
            type: 'group',
            icon: 'heroicons_outline:home',
            children: [
                {
                    id: 'crm.dashboard',
                    title: 'Dashboard',
                    type: 'basic',
                    icon: 'heroicons_outline:chart-pie',
                    link: '/dashboard/merch'
                },
                {
                    id: 'crm.clientes',
                    title: 'Clientes',
                    type: 'basic',
                    icon: 'heroicons_outline:users',
                    link: '/crm/customers'
                },
                {
                    id: 'usersProspects',
                    title: 'Leads',
                    type: 'basic',
                    icon: 'heroicons_outline:user',
                    link: '/crm/prospects'
                },
                {
                    id: 'crm.wishList',
                    title: 'Lista de Deseos',
                    type: 'basic',
                    icon: 'heroicons_outline:sparkles',
                    link: '/crm/wishList'
                },
                {
                    id: 'crm.messages',
                    title: 'Mensajes',
                    type: 'basic',
                    icon: 'heroicons_outline:inbox-in',
                    link: '/crm/messages'
                },
                // {
                //     id: 'crm.financePlan',
                //     title: 'Planes de Financiamiento',
                //     type: 'basic',
                //     icon: 'heroicons_outline:document-report',
                //     link: '/crm/financePlan'
                // },
                {
                    id: 'crm.adCampaign',
                    title: 'Campañas Publicitarias',
                    type: 'basic',
                    icon: 'heroicons_outline:megaphone',
                    link: '/crm/adCampaign'
                },
            ],
        },
        {
            id: 'inventory',
            title: 'Inventario',
            subtitle: 'Administra los productos y proveedores',
            type: 'group',
            icon: 'heroicons_outline:home',
            children: [
                {
                    id: 'inventory.products',
                    title: 'Productos',
                    type: 'basic',
                    icon: 'heroicons_outline:shopping-bag',
                    link: '/inventory/products'
                },
                {
                    id: 'inventory.supplier',
                    title: 'Proveedores',
                    type: 'basic',
                    icon: 'heroicons_outline:user-group',
                    link: '/inventory/suppliers'
                },
                {
                    id: 'inventory.brand',
                    title: 'Marcas',
                    type: 'basic',
                    icon: 'heroicons_outline:hashtag',
                    link: '/inventory/brands'
                },
                {
                    id: 'inventory.categoryProducts.category',
                    title: 'Categorías',
                    type: 'basic',
                    icon: 'heroicons_outline:tag',
                    link: '/inventory/categoryProducts/categories'

                },
                {
                    id: 'inventory.categoryProducts.subcategory',
                    title: 'Sub categorías',
                    type: 'basic',
                    icon: 'heroicons_outline:tag',
                    link: '/inventory/categoryProducts/subcategories'

                },
                {
                    id: 'inventory.characteristic',
                    title: 'Caracteristicas',
                    type: 'basic',
                    icon: 'heroicons_outline:search-circle',
                    link: '/inventory/characteristics'
                },
            ]
        },
        {
            id: 'ecommerce',
            title: 'Ecommerce',
            subtitle: 'Gestiona los datos del ecommerce',
            type: 'group',
            icon: 'heroicons_outline:home',
            children: [
                {
                    id: 'ecommerce.businessData',
                    title: 'Datos de la empresa',
                    type: 'basic',
                    icon: 'heroicons_outline:database',
                    link: '/ecommerce/businessData'
                },
                {
                    id: 'ecommerce.branch',
                    title: 'Sucursales',
                    type: 'basic',
                    icon: 'heroicons_outline:office-building',
                    link: '/ecommerce/branch'
                },
                {
                    id: 'ecommerce.heroSlider',
                    title: 'Hero Slider',
                    type: 'basic',
                    icon: 'heroicons_outline:collection',
                    link: '/ecommerce/heroSlider'
                },
                {
                    id: 'ecommerce.opinions',
                    title: 'Opiniones',
                    type: 'basic',
                    icon: 'heroicons_outline:pencil',
                    link: '/ecommerce/opinions'
                },
                {
                    id: 'ecommerce.banners',
                    title: 'Banners',
                    type: 'basic',
                    icon: 'heroicons_outline:newspaper',
                    link: '/ecommerce/banners'
                },
            ]
        }
    ];

    return nav;
}