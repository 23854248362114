import AdminNav from '#utils/nav-role/nav-admin';
import AfterSaleNav from '#utils/nav-role/nav-after-sale';
import CommunityManagerNav from '#utils/nav-role/nav-community-manager';
import ManagerNav from '#utils/nav-role/nav-manager';
import SalesAdvisorNav from '#utils/nav-role/nav-sales-advisor';
/* tslint:disable:max-line-length */
import SuperAdminNav from '#utils/nav-role/nav-super-admin';

export default function defaultNavigation() {
    const isSuperAdmin = localStorage.getItem("rol") == "SuperAdmin";
    const isAfterSale = localStorage.getItem("rol") == "AfterSale";
    const isManager = localStorage.getItem("rol") == "Manager";
    const isCommunityManager = localStorage.getItem("rol") == "CommunityManager";
    const isSalesAdvisor = localStorage.getItem("rol") == "SalesAdvisor";
    const isAdmin = localStorage.getItem("rol") == "Admin";
    if (isSuperAdmin) {
        return SuperAdminNav();
    }
    if (isAfterSale) {
        return AfterSaleNav();
    }

    if (isSalesAdvisor) {
        return SalesAdvisorNav();
    }

    if (isManager) {
        return ManagerNav();
    }

    if (isCommunityManager) {
        return CommunityManagerNav();
    }

    if (isAdmin) {
        return AdminNav();
    }
}