import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HeadersHttpService {
  public getHeadersPublic() {
    return new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }
  public getHeadersPrivate() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atob(localStorage.getItem('accessToken'))}`,
    });
  }
  public getHeadersFormDataPrivate() {
    return new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      'Authorization': `Bearer ${atob(localStorage.getItem('accessToken'))}`,
    });
  }
}