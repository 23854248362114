import { FuseNavigationItem } from '@fuse/components/navigation';

export default function AfterSaleNav() {

    const nav: FuseNavigationItem[] = [
        {
            id: 'rootcloud',
            title: 'Post-Venta',
            subtitle: 'Post-Venta',
            type: 'group',
            icon: 'heroicons_outline:home',
            children: [
                {
                    id: 'afterSaleDashboard',
                    title: "KPI's",
                    type: 'basic',
                    icon: 'mat_outline:area_chart',
                    link: '/rootcloud/dashboard'
                },
                {
                    id: 'devices',
                    title: 'Maquinaria',
                    type: 'basic',
                    icon: 'heroicons_outline:clipboard-list',
                    link: '/rootcloud/devices'
                },
                /* {
                    id: 'history',
                    title: 'Historico',
                    type: 'basic',
                    icon: 'heroicons_outline:folder',
                    link: '/rootcloud/history'
                }, */
                {
                    id: 'errors',
                    title: "Registro de fallas",
                    type: 'basic',
                    icon: 'mat_outline:wifi_tethering_error_rounded',
                    link: '/rootcloud/errors-log'
                },
                {
                    id: 'maintenance',
                    title: "Registro de mantenimientos",
                    type: 'basic',
                    icon: 'mat_outline:car_repair',
                    link: '/rootcloud/maintenance-log'
                },
                {
                    id: 'machinery-failures',
                    title: "Tipos de errores",
                    type: 'basic',
                    icon: 'mat_outline:healing',
                    link: '/rootcloud/machinery-failures'
                }
            ],
        }
    ];

    return nav;
}