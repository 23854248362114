import {
    NotificationsResponse,
} from '#interfaces/http-response/responses/http-responses';
import {
    Notification
} from '#root/core/layout/common/notifications/notifications.types';
import { UserStorageService } from '#root/core/user/user.service';
import { HeadersHttpService } from '#utils/http-headers/http-headers.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'environments/environment';
import {
    BehaviorSubject,
    Observable,
    tap,
} from 'rxjs';

@Injectable({
    providedIn: "root"
})
export class SignalRService {

    private _notification: BehaviorSubject<Notification[]> = new BehaviorSubject([]);

    get notification(): Observable<Notification[]> {
        return this._notification.asObservable();
    }

    constructor(private httpClient: HttpClient, private userService: UserStorageService, private headerService: HeadersHttpService) { }

    private hubConnection: signalR.HubConnection
    private baseUrl = environment.baseUrl + "Notification/"

    public startConnection = () => {


        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(environment.signalRUrl, { accessTokenFactory: () => atob(localStorage.getItem("accessToken")) })
            .configureLogging(signalR.LogLevel.None)
            .withAutomaticReconnect()
            .build();


        this.hubConnection.start().then(() => {
            this.httpClient.get<NotificationsResponse>(this.baseUrl + "GetAllUnread/" + atob(this.userService.get().id), { headers: this.headerService.getHeadersPrivate() })
                .pipe(
                    tap((res) => {
                        this._notification.next(res.data);
                    })
                ).subscribe();
        })
            .catch(err => console.log(err))

        this.hubConnection.on('newnotification', (data) => {
            this._notification.next([data, ...this._notification.value]);
        })
    }

    public async closeConnection() {
        await this.hubConnection?.stop();
    }
}